'use strict'

const isError_ = require('lodash/isError')

const { wixCodeLogLevel } = require('./wixCodeLogLevel')
const { callbackRegistrar } = require('./callbackRegistrar')

const consoleMethodsToWrap = {
  info: wixCodeLogLevel.INFO,
  warn: wixCodeLogLevel.WARN,
  error: wixCodeLogLevel.ERROR,
  log: wixCodeLogLevel.LOG
}

function _toArray(args) {
  return Array.prototype.map.call(args, function(v) {
    if (v instanceof Error || v instanceof Date) {
      return v.toString()
    }
    const vt = typeof v
    if (
      vt === 'string' ||
      vt === 'boolean' ||
      vt === 'number' ||
      v === null ||
      v === undefined
    ) {
      return v
    }
    if (Array.isArray(v)) {
      return _toArray(v)
    }
    if (vt === 'function') {
      return v.toString()
    }

    try {
      return JSON.stringify(v)
    } catch (e) {
      return v.toString()
    }
  })
}

function wrapConsoleMethod(consoleInstance, logLevel, originalFunc, onLog) {
  return function() {
    const stack = isError_(arguments[0])
      ? arguments[0].stack
      : new Error().stack
    const args = _toArray(arguments)

    const messageData = {
      logLevel,
      args,
      stack
    }
    onLog(messageData)
    originalFunc.apply(consoleInstance, arguments)
  }
}

function wrapConsole(consoleInstance) {
  const { register: onLog, call: callOnLogListeners } = callbackRegistrar()

  if (consoleInstance) {
    const originalLog = consoleInstance.log || (() => {})
    for (const method in consoleMethodsToWrap) {
      if (
        consoleMethodsToWrap.hasOwnProperty(method) &&
        consoleInstance.hasOwnProperty(method)
      ) {
        consoleInstance[method] = wrapConsoleMethod(
          consoleInstance,
          consoleMethodsToWrap[method],
          consoleInstance[method],
          callOnLogListeners
        )
      }
    }
    consoleInstance.verbose = wrapConsoleMethod(
      consoleInstance,
      wixCodeLogLevel.VERBOSE,
      originalLog,
      callOnLogListeners
    )

    //TODO: remove right after WCD-6723 is complete
    consoleInstance.serverLog = () => {}
  }

  return onLog
}

module.exports = {
  wrapConsole
}
